import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <main>
      <div className="container px-5">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-xxl-8">
            <h1>お探しのページが見つかりませんでした。</h1>
            <br/>
            <h4>
              大変申し訳ございませんが、お探しのページまたはファイルが見つかりませんでした。
              アドレスが正しく入力されているかもう一度ご確認ください。
            </h4>
          </div>
        </div>
      </div>
    </main>
  </Layout>
)

export default NotFoundPage
